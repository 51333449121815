import "../../layout.css";
import "./photosModal.css";
import close from "../../images/close.svg";

function PhotosModal({visible, setModal, photos, addToValue}) {

    function addImagePath(photo) {
        addToValue(`![alt](${process.env.REACT_APP_SERVER_URL}${photo?.path})`);
        setModal(false);
    }

    return (
        <div className={visible ? "photos-modal" : "none"}>
            <img src={close} alt={"close"} width={30} height={30} onClick={() => {setModal(false)}} className={"photos-modal-close-button"} />
            {photos.length > 0 && (<h5 className={"photos-modal-header"}>Kliknij w zdjęcie, aby dodać na stronę menu</h5>)}
            <div className={"photos-modal-content"}>
                {photos.reverse().map((photo, index) => {
                    return (<div key={index} className={"photos-modal-image-container"} onClick={() => {addImagePath(photo)}}>
                        <img src={`${process.env.REACT_APP_SERVER_URL}${photo?.path}`} alt={"gallery-photo"} className={"photos-modal-image"} />
                    </div>);
                })}
            </div>
        </div>
    );
}

export default PhotosModal;
