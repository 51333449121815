export const getMenu = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/user/me`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Fetch error");
    } catch (e) {
        throw new Error("Fetch error");
    }
};

export const saveMenu = async (token, content, publish) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/menu/draft` + (publish ? "?publish=true" : ""), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({content: content}),
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Save error");
    } catch (e) {
        throw new Error("Save error");
    }
};

export const saveConfig = async (token, config, logoFile, backgroundImageFile, logo, backgroundImage) => {
    const formData = new FormData();
    if (config) {
        Object.entries(config).forEach(([key, value]) => {
            formData.append(key, value);
        })
    }
    if (logoFile) {
        formData.append("logo", logoFile)
    }
    if (backgroundImageFile) {
        formData.append("file", backgroundImageFile)
    }
    if (!logo) {
        formData.append("removeLogo", true);
    } else {
        formData.append("removeLogo", false);
    }

    if (!backgroundImage) {
        formData.append("removeFile", true);
    } else {
        formData.append("removeFile", false);
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/menu/configuration`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: formData,
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Save error");
    } catch (e) {
        throw new Error("Save error");
    }
};

export const uploadImage = async (token, image) => {
    const formData = new FormData();
    formData.append('file', image);
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/user/gallery`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: formData,
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Image upload error");
    } catch (e) {
        throw new Error("Image upload error");
    }
};

export const getMenuById = async (id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/menu/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Fetch error");
    } catch (e) {
        throw new Error("Fetch error");
    }
};

export const getGallery = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}api/user/gallery`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });
        const res = await response.json();
        if (res?.ok === 1) {
            return res;
        }
        throw new Error("Fetch error");
    } catch (e) {
        throw new Error("Fetch error");
    }
};

const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
}

export const styleMarkdown = async (configuration, fontOptions, preview, backgroundImage) => {
    if (configuration) {
        let markdownContainer = window.document.getElementsByClassName("markdown-container")[0];
        if (preview) {
            if (backgroundImage) {
                markdownContainer.parentElement.classList.remove("dark-mode");
                markdownContainer.parentElement.style.background = `url("${backgroundImage}")`
                markdownContainer.parentElement.style.backgroundSize = `cover no-repeat`
            } else {
                markdownContainer.parentElement.style.background = 'none';
                if (configuration?.shadowMode) {
                    markdownContainer.parentElement.classList.add("dark-mode");
                } else {
                    markdownContainer.parentElement.classList.remove("dark-mode");
                    markdownContainer.parentElement.style.background = configuration.backgroundColor;
                }
            }
        } else {
            if (backgroundImage) {
                window.document.body.classList.remove("dark-mode");
                window.document.body.style.background = `url("${process.env.REACT_APP_SERVER_URL}${backgroundImage}")`
                window.document.body.style.backgroundSize = `cover no-repeat`
                window.document.body.style.backgroundAttachment = `fixed`
                window.document.body.style.minHeight = `100vh`
            } else {
                window.document.body.style.background = 'none';
                if (configuration?.shadowMode) {
                    window.document.body.classList.add("dark-mode");
                } else {
                    window.document.body.classList.remove("dark-mode");
                    window.document.body.style.background = configuration.backgroundColor;
                }

            }
        }
        for (const child of markdownContainer.children) {
            child.style.textAlign = configuration.align;
            if (["P", "SPAN"].includes(child.tagName)) {
                if (isColor(configuration.colorText)) {
                    child.style.color = configuration.colorText;
                } else {
                    if (configuration?.shadowMode) {
                        child.style.color = "#FFFFFF";
                    } else {
                        child.style.color = "#000000";
                    }
                }
                child.style.fontFamily = `"${fontOptions[configuration.fontText]}"`;
            }
            if (["H1", "H2", "H3", "H4", "H5"].includes(child.tagName)) {
                if (isColor(configuration.colorHeader)) {
                    child.style.color = configuration.colorHeader;
                }
                child.style.fontFamily = `"${fontOptions[configuration.fontHeader]}"`;
            }

            if (["UL"].includes(child.tagName)) {
                for (const grandChild of child.children) {
                    if (["LI"].includes(grandChild.tagName)) {
                        if (isColor(configuration.colorText)) {
                            grandChild.style.color = configuration.colorText;
                        } else {
                            if (configuration?.shadowMode) {
                                grandChild.style.color = "#FFFFFF";
                            } else {
                                grandChild.style.color = "#000000";
                            }
                        }
                        grandChild.style.fontFamily = `"${fontOptions[configuration.fontText]}"`;
                        grandChild.style.textAlign = configuration.align;
                    }
                }
            }

            if (["TABLE"].includes(child.tagName)) {
                for (const grandChild of child.children) {
                    if (["THEAD", "TBODY"].includes(grandChild.tagName)) {
                        if (isColor(configuration.colorText)) {
                            grandChild.style.color = configuration.colorText;
                        } else {
                            if (configuration?.shadowMode) {
                                grandChild.style.color = "#FFFFFF";
                            } else {
                                grandChild.style.color = "#000000";
                            }
                        }
                        grandChild.style.fontFamily = `"${fontOptions[configuration.fontText]}"`;
                        grandChild.style.textAlign = configuration.align;
                    }
                }
            }
        }
    }
};

export default {getMenu, saveMenu, uploadImage, getMenuById, getGallery, saveConfig, styleMarkdown};